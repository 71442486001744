<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="分拣员" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="名称/联系方式"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <!-- 不是地区账号加地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="!city && !province">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset2">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建分拣员</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:address="{row}">
        <span>{{row.province_str}} {{row.city_str}}</span>
      </template>
      <template v-slot:cate_count="{row}">
        <span>{{row.cate_count}}个</span>
      </template>
      <template v-slot:merchant_arr="{row}">
        <span v-for="(item,index) in row.merchant_arr" :key="index">
          {{item}}
          <span v-if="index < row.merchant_arr.length - 1">、</span>
        </span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="handlePwd(row)">修改密码</el-button>
        <el-button type="text" @click="remove(row)" v-if="is_sys == 1">删除</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>

    <!-- 修改密码 -->
    <NewPwd ref="newpwd"></NewPwd>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import NewPwd from './components/newPwd.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'
export default {
  name: 'sorterIndex',
  computed:{
    ...mapGetters(['areaArr'])
  },
  components: {
    Edit,
    NewPwd,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      province: getStorage('province'),  // 有地区就是区账号
      city: getStorage('city'),  // 有地区就是区账号
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      is_sys: this.$store.state.is_sys, // 1:只有超级管理员才有列表的删除按钮
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "分拣员名称", width:'140', hidden: false },
        { name: "account", label: "联系方式", width:'140', hidden: false},
        { name: "address", label: "所属地区", width:'180',  hidden: false},
        { name: "cate_count", label: "负责商品类别数", width:'140',  hidden: false},
        { name: "merchant_arr", label: "负责客户", showTooltip:true, hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          area: [],
          province: '',
          city: '',
          page: 1,
          count: 20
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _area = this.table.params.area;
      if(_area.length == 1) {
        this.table.params.province = _area[0]
      } else if(_area.length == 2) {
        this.table.params.province = _area[0]
        this.table.params.city = _area[1]
      } else {
        this.table.params.province = '';
        this.table.params.city = '';
      }
      this.table.loading = true;
      this.$http.get('/admin/sorter/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 修改密码
    handlePwd(row) {
      let dom = this.$refs.newpwd
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/sorter/delete"
      );
    },
  }
}
</script>
